<script setup>
// Date List
import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/vue";
// don't display, because a) text is only available in German and b) German Text needs correction (Artikelverwendung)
// eslint-disable-next-line no-unused-vars
import VarusTodayIs from "../components/VarusTodayIs.vue";
// eslint-disable-next-line no-unused-vars
import VarusChipTune from "../components/VarusChipTune.vue";
// eslint-disable-next-line no-unused-vars
import VarusChipFasting from "../components/VarusChipFasting.vue";
import VarusChurchSeasonText from "../components/VarusChurchSeasonText.vue";
import VarusChipDate from "../components/VarusChipDate.vue";
import { useCalendarStore } from "../stores/calendarStore";
import { computed } from "vue";

defineProps({
  size: {
    type: String,
    required: false,
    validator: (value) => ["small", "normal"].includes(value),
    default: "normal",
  },
});

const maxHeight = computed(() => {
  return calStore.isSeasonImageWide ? "340px" : "auto";
});

const maxWidth = computed(() => {
  return calStore.isSeasonImageWide ? "auto" : "450px";
});

const maxHeightSmall = computed(() => {
  return calStore.isSeasonImageWide ? "190px" : "auto";
});

const maxWidthSmall = computed(() => {
  return calStore.isSeasonImageWide ? "auto" : "230px";
});

// calStore
const calStore = useCalendarStore();
</script>

<template>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-align-self-center">
        <varus-church-season-text />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-align-self-center image">
        <ion-img :class="size == 'small' ? 'seasonPicSmall ' : 'seasonPic'" :src="calStore.seasonPicPath" />
      </ion-col>
      <ion-col class="ion-align-self-center">
        <varus-chip-date cal="gregory" :linkToCal="true" />
        <br /><br />
        <varus-chip-date cal="coptic" :linkToCal="true" />
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<style scoped>
ion-col {
  text-align: center;
  border: 0;
}

ion-col.image {
  display: flex; /* Aktiviert Flexbox für die Zentrierung */
  justify-content: center; /* Zentriert horizontal */
  align-items: center; /* Zentriert vertikal */
}

.seasonPic,
.seasonPicSmall {
  width: auto; /* Behält das Seitenverhältnis bei */
  height: auto; /* Behält das Seitenverhältnis bei */
  background: var(--ion-color-copticicon);
  border-radius: 12px;
  padding: 5px;
}

.seasonPic {
  max-height: v-bind("maxHeight");
  max-width: v-bind("maxWidth");
}

.seasonPicSmall {
  max-height: v-bind("maxHeightSmall");
  max-width: v-bind("maxWidthSmall");
}
</style>
