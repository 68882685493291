import { useReadingsStore } from "../stores/readingsStore";
import { configs } from "../utils/ConfigUtil";
import { DebugLevel, appLog } from "../functions/Logging";

const CSS_INTRO_START = "<span class='readingIntroAuthor'>";
const CSS_INTRO_END = "</span>";
const FILE_NAME = "[ReadingService]";

// whether to attach CSS span-tag or not to the replaced reading-variable
let formatReplacedReadingVariable = {};
formatReplacedReadingVariable["author"] = true;
formatReplacedReadingVariable["ordinal"] = false;
formatReplacedReadingVariable["recipient"] = true;

function getReadingAsPrayer(readingType, reading) {
  appLog(DebugLevel.DEBUG, FILE_NAME + "[joinReadings2Prayer]", "readingType: ", readingType);
  let prayers = [];
  prayers.push(getReadingAsPrayerInternal(readingType, reading));
  appLog(DebugLevel.TRACE, FILE_NAME + "[joinReadings2Prayer]", "returning prayers: ", prayers);
  return prayers;
}

function getReadingAsPrayerInternal(readingType, readingFromStaticKatameros) {
  appLog(DebugLevel.DEBUG, FILE_NAME + "[joinReadings2Prayer]", "readingType: ", readingType);
  const rType = configs.getReadingTypeByKey(readingType);
  const text = readingFromStaticKatameros;
  const rPara = getParagraphsFromReading(readingFromStaticKatameros);

  // Replace placeholders in Reading Intro--Texts
  rType["intro"]["deu"] = substituteReadingVariables(rType.intro.deu, readingFromStaticKatameros, "deu");
  rType["intro"]["ara"] = substituteReadingVariables(rType.intro.ara, readingFromStaticKatameros, "ara");

  return {
    type: "prayer",
    key: rType.key,
    titleDisplay: {
      deu: rType?.titleDisplay?.deu,
      ara: rType?.titleDisplay?.ara,
      cop: text?.reference,
    },
    collapsed: false,
    paragraphs: [
      {
        type: "intro",
        text: {
          deu: [rType?.intro?.deu],
          ara: [rType?.intro?.ara],
          cop: [rType?.intro?.cop],
        },
      },
      {
        type: "text",
        text: {
          deu: rPara == undefined ? "" : rPara?.text?.deu,
          ara: rPara == undefined ? "" : rPara?.text?.ara,
          cop: rPara == undefined ? "" : rPara?.text?.cop,
        },
      },
      {
        type: "intro",
        text: {
          deu: rType?.extro == undefined ? "" : [rType?.extro?.deu],
          ara: rType?.extro == undefined ? "" : [rType?.extro?.ara],
          cop: rType?.extro == undefined ? "" : [rType?.extro?.cop],
        },
      },
    ],
  };
}

function getParagraphsFromReading(reading) {
  if (reading === undefined) {
    reading = {};
  }
  if (reading.paragraphs === undefined) {
    reading["paragraphs"] = [];
  }
  if (reading.paragraphs[0] === undefined) {
    reading.paragraphs[0] = "";
  }
  return reading.paragraphs[0];
}

function substituteReadingVariables(introPlaceHold, reading, lang) {
  if (reading === undefined) return introPlaceHold;
  const regExp = /\{([^}]+)\}/g;
  const matches = [...introPlaceHold.matchAll(regExp)];
  matches.forEach((match) => {
    const varName = match[1];
    const varNameFull = match[0];
    if (Object.prototype.hasOwnProperty.call(reading, varName) && reading[varName] != "") {
      // ordinal currently needs a space at the end
      const space = varName == "ordinal" ? " " : "";
      // whether to add or nor add CSS-Tags
      const cssTagStart = formatReplacedReadingVariable[varName] ? CSS_INTRO_START : "";
      const cssTagEnd = formatReplacedReadingVariable[varName] ? CSS_INTRO_END : "";
      // is the variable-value available in languages
      let value = "";
      if (reading[varName][lang] !== undefined) {
        value = reading[varName][lang][0];
      } else {
        value = reading[varName];
      }
      // Replace
      introPlaceHold = introPlaceHold.replace(varNameFull, cssTagStart + value + space + cssTagEnd);
    } else {
      // remove variable
      introPlaceHold = introPlaceHold.replace(varNameFull, "");
    }
  });
  return introPlaceHold;
}

function getTableOfReadings(service) {
  if (typeof service === "undefined") {
    return undefined;
  }
  // Store
  const readingsStore = useReadingsStore();
  let tableOfContents = [];
  appLog(
    DebugLevel.DEBUG,
    FILE_NAME + "[getTableOfReadings]",
    `for service ${service}: `,
    configs[service].readingTypes
  );
  configs[service].readingTypes.forEach((reading) => {
    const rType = configs.getReadingTypeByKey(reading);
    let readingToC = {
      key: rType?.key,
      titleDisplay: {
        deu:
          rType?.titleDisplay?.deu +
          "<br>(" +
          readingsStore.reading?.liturgyServices[service][reading]?.reference +
          ")",
        ara:
          rType?.titleDisplay?.ara +
          "<br>(" +
          readingsStore.reading?.liturgyServices[service][reading]?.reference +
          ")",
      },
    };
    tableOfContents.push(readingToC);
  });
  return tableOfContents;
}

export { getReadingAsPrayer, getTableOfReadings };
