import { defineStore } from "pinia";
import { DebugLevel, appLog } from "../functions/Logging";

import { CALENDARS } from "@/enums/Calendars.js";
import { getCopticEasterByGregYear } from "../services/CopticEasterService";
import { calendarDate } from "../classes/CalendarDate.js";
import { differenceInDays, addDays, isAfter } from "date-fns";
import { getFeasts } from "../seasonservice/FeastService.js";
import { getFasts } from "../seasonservice/FastService.js";
import { getSeasons } from "../seasonservice/SeasonService.js";
import { getCurrentTunes } from "../seasonservice/TunesService.js";
import { useReadingsStore } from "./readingsStore"; // Import the new readingsStore

// Constants
export const STYLE = "short";

// TODO: move to configuration
const dayTransitionTime_Hour = "18";
const dayTransitionTime_Min = "00";

const storeName = "CalendarStore";

appLog(DebugLevel.INFO, "[Pinia]", `${storeName} initializing ...`);

export const useCalendarStore = defineStore("calendar", {
  state: () => ({
    initialized: false,
    language: null,
    date: null,
    dayOfWeek: null,
    dayTransitionTime: null,
    eveOf: false,
    varusDate: new Map(),
    easterDate: null,
    daysToEastern: null,
    lentStart: null,
    daysBetweenTodayAndLentStart: null,
    daysLengthOfApostleFast: null,
    tune: null,
    feast: null,
    churchSeason: null,
    seasonPicPath: null,
    isLordFeast: false,
  }),

  getters: {
    getDate: (state) => (cal) => state.varusDate.get(cal),
    getDateGreg: (state) => state.varusDate.get(CALENDARS.GREG),
    getDateCopt: (state) => state.varusDate.get(CALENDARS.COPT),
    getCopticMonth: (state) => state.varusDate.get(CALENDARS.COPT)?.mnShort,
    getChurchSeason: (state) => state.churchSeason?.[0],
    getTune: (state) => state.tune?.[0],
    getFasting: (state) => {
      const season = state.churchSeason?.[0] || {};
      return {
        fasting: season.fasting,
        fasting_fish_allowed: season.fasting_fish_allowed,
        fasting_strict: season.fasting_strict,
      };
    },
    getCopticEaster: (state) => (cal) => {
      const gregDate = state.varusDate.get(CALENDARS.GREG);
      const easter = getCopticEasterByGregYear(gregDate.y);
      return calendarDate(state.language, cal, new Date(easter.y, easter.m - 1, easter.d));
    },
    getCopticEasterGregorian: (state) => {
      return state.getCopticEaster(CALENDARS.GREG);
    },
    getCopticEasterCoptic: (state) => {
      return state.getCopticEaster(CALENDARS.COPT);
    },
    isSeasonImageWide: (state) => {
      return state.churchSeason?.[0].image_aspect == null ? false : true;
    },
    // seasonContext = object which conditionally controls rendering of prayers
    getSeasonContext: (state) => {
      return {
        season: state.churchSeason?.[0],
        dayOfWeek: state.dayOfWeek,
        isLordFeast: state.isLordFeast,
        daysToEastern: state.daysToEastern,
        copticDate: { month: state.varusDate.get(CALENDARS.COPT).m, day: state.varusDate.get(CALENDARS.COPT).d },
      };
    },
  },

  actions: {
    initializeCalendarStore(language, date = new Date()) {
      if (this.initialized) return;
      this.setDate(language, date);
      this.initialized = true;
    },

    setTransitionTime(date) {
      this.dayTransitionTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        dayTransitionTime_Hour,
        dayTransitionTime_Min
      );
    },

    calculateDates(date) {
      const isCopticEve = isAfter(date, this.dayTransitionTime);
      this.eveOf = isCopticEve;
      const dayOffset = isCopticEve ? 1 : 0;

      this.varusDate.set(CALENDARS.GREG, calendarDate(this.language, CALENDARS.GREG, date));
      this.varusDate.set(CALENDARS.COPT, calendarDate(this.language, CALENDARS.COPT, addDays(date, dayOffset)));
    },

    loadSeasonalData() {
      const gregDate = this.varusDate.get(CALENDARS.GREG);
      const easter = getCopticEasterByGregYear(gregDate.y);
      this.easterDate = calendarDate(this.language, CALENDARS.GREG, new Date(easter.y, easter.m - 1, easter.d));

      this.daysToEastern = differenceInDays(this.easterDate.date, this.date);
      this.lentStart = addDays(this.easterDate.date, -55);
      this.daysBetweenTodayAndLentStart = differenceInDays(this.lentStart, this.date);

      const pentecost = addDays(this.easterDate.date, 50);
      const apostleFast = new Date(this.easterDate.date.getFullYear(), 6, 12);
      this.daysLengthOfApostleFast = differenceInDays(apostleFast, pentecost);

      const coptDate = this.varusDate.get(CALENDARS.COPT);
      this.tune = getCurrentTunes(coptDate, this.daysToEastern);
      this.feast = getFeasts(coptDate, this.daysToEastern);
      this.fast = getFasts(coptDate, this.daysToEastern);
      this.churchSeason = getSeasons(coptDate, this.dayOfWeek, this.daysToEastern);

      // Initialize ReadingsStore
      const readingsStore = useReadingsStore();
      readingsStore.initializeReadings(this.churchSeason[0], this.dayOfWeek, this.daysToEastern, coptDate);

      this.setImagePath();
      this.isLordFeast = this.churchSeason?.[0]?.feast_major ?? this.churchSeason?.[0]?.feast_minor ?? false;
    },

    setImagePath() {
      const seasonIndex = 0;
      const season = this.churchSeason?.[seasonIndex];
      const imageName = season?.image || season.key;
      try {
        this.seasonPicPath = `@/../assets/images/seasons/${imageName}.png`;
      } catch {
        this.seasonPicPath = `@/../assets/images/seasons/annual.png`;
      }
    },

    setDate(language, date = new Date()) {
      this.language = language;
      this.date = date;
      this.dayOfWeek = date.getDay();

      this.setTransitionTime(date);
      this.calculateDates(date);
      this.loadSeasonalData();
    },
  },
});

appLog(DebugLevel.INFO, "[Pinia]", `${storeName} initialized.`);
